import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCardLinkById } from '../services/cardService';
import LoadingScreen from '../components/LoadingScreen';
import Error from '../components/Error';

const Cards: React.FC = () => {
    const [cardLink, setCardLink] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { id: cardId } = useParams<{ id: string }>();
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchLink = async () => {
            if (!cardId) {
                setError("L'identifiant de la carte est manquant.");
                setLoading(false);
                return;
            }
            const result = await fetchCardLinkById(cardId);

            if (result.error === 'invalid_id') {
                setError(`Le lien de la carte avec l'identifiant ${cardId} est invalide ou n'existe pas.`);
            } else if (result.error === 'empty_link') {
                setError(`Aucun profil n'est associé à la carte avec l'identifiant ${cardId}.`);
            } else if (result.link) {
                setCardLink(result.link);
            }

            setLoading(false);
        };

        fetchLink();
    }, [cardId]);

    useEffect(() => {
        if (cardLink && baseUrl) {
            const fullLink = `${baseUrl}profile/${cardLink}`;
            window.location.href = fullLink;
        }
    }, [cardLink, baseUrl]);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return null;
};

export default Cards;
